import React from 'react';
import ImgEmConstrucao from './img/em_construcao.png';
import ImgErroServidor from './img/erro_servidor.png';
import ImgVazio from './img/vazio.png';
import ImgAcessoNegado from './img/acesso_negado2.png';
import './estilo.css'
import { Typography } from '@mui/material';
import Fade from '@mui/material/Fade';

const erros = {
    "construcao" : {
        titulo: 'Em construção',
        texto: 'Estamos trabalhando na página que você tentou acessar, mas não se preocupe, em breve ela estará disponível.',
        imagem: ImgEmConstrucao
    },
    "servidor": {
        titulo: 'Erro no servidor',
        texto: 'Ops! Encontramos um erro em nossos servidores, tente acessar essa página novamente mais tarde.',
        imagem: ImgErroServidor
    },
    "resultado": {
        titulo: 'Nenhum resultado encontrado',
        texto: 'Não encontramos nenhum resultado para a pesquisa realizada. Tente novamente.',
        imagem: ImgVazio
    },
    "404": {
        titulo: 'Erro 404',
        texto: 'Página não encontrada.',
        imagem: ImgVazio
    },
    "acesso": {
        titulo: 'Acesso negado',
        texto: 'Detectamos que você não possui permissão para acessar esta página.',
        imagem: ImgAcessoNegado
    }
}

const MensagemErro = (props) => {
    const erro = erros[props.tipo]
    
    return (
        <Fade in={true} timeout={800}>
            <div className="erro__container">
                <img src={erro.imagem} alt="" className="erro__imagem" />
                <Typography variant="h3" className="erro__titulo" sx={{fontWeight: "300"}}>
                    {erro.titulo}
                </Typography>
                <Typography variant="body1" className="erro__texto">
                    {erro.texto}
                </Typography>
            </div>
        </Fade>
    );
}

export default MensagemErro;