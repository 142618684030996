import React from 'react';
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import './estilo.css';

const ListaFotosParque = ({ parque }) => {
    const fotosArr = [parque.foto1, parque.foto2, parque.foto3, parque.foto4, parque.foto5, parque.foto6]
    const fotosPath = fotosArr?.filter((foto) => foto != null)
    const options = {
      settings: {
        disablePanzoom: true,
        slideAnimationType: 'slide',
      },
      buttons: {
        showAutoplayButton: false,
        showDownloadButton: false,
        showFullscreenButton: false,
        showThumbnailsButton: false,
      },
      caption: {
        showCaption: false
      }
    };
    
    const Conteudo = () => {
      var quantidadeFotos = fotosPath.length;
      const fotos = fotosPath?.map((foto, i) => {
        return(
          <img 
            src={foto} 
            key={i} 
            className="fotosParque" 
            style={{objectFit: "cover", verticalAlign: "bottom", width: `calc( 100% / ${quantidadeFotos} - 5px )`}}
            alt="Fotos do parque"/>
        )
      })
      return (
        <section className="lista-fotos-parques">
          <div className="grid-fotos-parques">
              {fotos}
          </div>
        </section>
      );
      
    }

    return (
      <SimpleReactLightbox>
        <SRLWrapper options={options}>
          <Conteudo />
        </SRLWrapper>
      </SimpleReactLightbox>
    );
}

export default ListaFotosParque;