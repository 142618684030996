import React from 'react';
import BannerDengue from '../BannerDengue';
import ListaParques from '../ListaParques';

const PaginaPrincipal = ({ pesquisa, pagina, setPagina, estaCarregado, setEstaCarregado, setParqueSelecionado }) => {
    return (
        <>
            <ListaParques 
                pesquisa={pesquisa} 
                estaCarregado={estaCarregado} 
                setEstaCarregado={setEstaCarregado}
                pagina={pagina} 
                setPagina={setPagina}
                setParqueSelecionado={setParqueSelecionado}
            />
        </>
    );
}

export default PaginaPrincipal;