import React, { useState } from 'react';
import Header from './components/Header';
import PaginaPrincipal from './components/PaginaPrincipal';
import PaginaQuemSomos from './components/PaginaQuemSomos';
import PaginaFaq from './components/PaginaFaq';
import PaginaNaoEncontrada from './components/PaginaNaoEncontrada';
import Footer from './components/Footer';
import DetalhesParque from './components/DetalhesParque';
import './App.css';
import { Routes, Route } from 'react-router-dom';

function App() {
  const [pesquisa, setPesquisa] = useState("");
  const [estaCarregado, setEstaCarregado] = useState(false);
  const [pagina, setPagina] = useState(1);
  const [parqueSelecionado, setParqueSelecionado] = useState(false);

  return (
    <>
      <Header 
          pesquisa={pesquisa} 
          setPesquisa={setPesquisa} 
          setEstaCarregado={setEstaCarregado}
          pagina={pagina} 
          setPagina={setPagina}
          parqueSelecionado={parqueSelecionado}
      />
      
      <Routes>
        <Route path="/" element={ 
          <PaginaPrincipal 
            pesquisa={pesquisa} 
            setPesquisa={setPesquisa}
            estaCarregado={estaCarregado}
            setEstaCarregado={setEstaCarregado}
            pagina={pagina}
            setPagina={setPagina}
            setParqueSelecionado={setParqueSelecionado} /> 
          } 
        />

        <Route path=":ulrDoParque" element={ 
          <DetalhesParque setParqueSelecionado={setParqueSelecionado}/> } 
        />

        <Route path="/quem-somos" element={ 
          <PaginaQuemSomos setParqueSelecionado={setParqueSelecionado}/> } 
        />

        <Route path="/faq" element={ 
          <PaginaFaq setParqueSelecionado={setParqueSelecionado}/> } 
        />

        <Route path="*" element={ 
          <PaginaNaoEncontrada setParqueSelecionado={setParqueSelecionado}/> } 
        />
      </Routes>

      <Footer />
    </>
  );
}

export default App;
