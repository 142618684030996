import React, { useState } from 'react';
import TuneIcon from '@mui/icons-material/Tune';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Divider, Button, Badge, FormLabel } from '@mui/material';
import './estilo.css';

const Filtro = ({ mudancaRegiao, mudancaTipo }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [regioesSelecionadas, setRegioesSelecionadas] = useState({
        CO: false,
        L: false,
        N: false,
        S: false
    });
    const [tiposSelecionados, setTiposSelecionados] = useState({
        bordaRepresa: false,
        linear: false,
        natural: false,
        preservacao: false,
        urbano: false
    });
    const [contador, setContador] = useState();
    
    const open = Boolean(anchorEl);
    const {CO, L, N, S} = regioesSelecionadas;
    const {bordaRepresa, linear, natural, preservacao, urbano} = tiposSelecionados;
    const dicionarioRegioes = {
        "bordaRepresa": "Borda Represa",
        "linear": "Linear",
        "natural": "Natural",
        "preservacao": "Preservação",
        "urbano": "Urbano"
    }

    const handleChangeRegioes = (event) => {
        setRegioesSelecionadas({
            ...regioesSelecionadas,
            [event.target.name]: event.target.checked,
        });
    };

    const handleChangeTipos = (event) => {
        setTiposSelecionados({
            ...tiposSelecionados,
            [event.target.name]: event.target.checked,
        });
    };

    const enviaMudancasFiltro = (event) => {
        const keysRegiao = Object.keys(regioesSelecionadas);
        const arrayRegiao = [];

        const keysTipo = Object.keys(tiposSelecionados);
        const arrayTipo = [];

        keysRegiao.forEach((key, index) => {
            if (regioesSelecionadas[key]) {
                arrayRegiao.push(key);
            }
        });

        keysTipo.forEach((key, index) => {
            if (tiposSelecionados[key]) {
                arrayTipo.push(dicionarioRegioes[key]);
            }
        });

        setContador(arrayRegiao.length + arrayTipo.length)
        mudancaRegiao(arrayRegiao);
        mudancaTipo(arrayTipo);
        handleClose();
    };

    const limpaMudancasFiltro = (event) => {
        setRegioesSelecionadas({
            CO: false,
            L: false,
            N: false,
            S: false
        });

        setTiposSelecionados({
            bordaRepresa: false,
            linear: false,
            natural: false,
            preservacao: false,
            urbano: false
        });
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const stopPropagationParaTab = (evento) => {
        if (evento.key === "Tab") {
            evento.stopPropagation();
        }
    }
    
    return (
        <div className="filtro__container">
            
            <Badge 
                badgeContent={contador} 
                color="primary"
            >
                <Fab variant="extended" sx={{ background: '#ffffff', boxShadow: 3 }} onClick={handleClick} >
                    <TuneIcon color="action" sx={{ mr: 0.5 }} />
                    <Typography sx={{ textTransform: 'none' }}>Filtros</Typography>
                </Fab>
            </Badge>

            <div className="menu">
                
                <Menu
                    id="menu-filtros"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    sx={{ position: 'absolute', '& .MuiMenu-paper': { borderRadius: 3, top: '150px !important' } }}
                    disableScrollLock={true}
                >
                    
                    <FormGroup tabIndex={-1} onKeyDown={stopPropagationParaTab} sx={{marginTop: "-8px", marginBottom: "-8px"}}>
                        
                        <div className="regiao">
                            <FormLabel sx={{ fontWeight: 'bold', color: 'black' }}>Região</FormLabel>
                            <div className="regiao__items">
                                <FormControlLabel control={<Checkbox name="CO" checked={CO} onChange={handleChangeRegioes} />} label="Centro-Oeste" />
                                <FormControlLabel control={<Checkbox name="L" checked={L} onChange={handleChangeRegioes} />} label="Leste" />
                                <FormControlLabel control={<Checkbox name="N" checked={N} onChange={handleChangeRegioes} />} label="Norte" />
                                <FormControlLabel control={<Checkbox name="S" checked={S} onChange={handleChangeRegioes} />} label="Sul" />
                            </div>
                        </div>

                        <Divider sx={{margin: "0 16px"}}/>

                        <div className="tipo">
                            <FormLabel sx={{ fontWeight: 'bold', color: 'black' }}>Tipo</FormLabel>
                            <div className="tipo__items">
                                <FormControlLabel control={<Checkbox name={"bordaRepresa"} onChange={handleChangeTipos} checked={bordaRepresa}/>} label="Borda Represa" />  
                                <FormControlLabel control={<Checkbox name={"linear"} onChange={handleChangeTipos} checked={linear}/>} label="Linear" />
                                <FormControlLabel control={<Checkbox name={"natural"} onChange={handleChangeTipos} checked={natural}/>} label="Natural" />
                                <FormControlLabel control={<Checkbox name={"preservacao"} onChange={handleChangeTipos} checked={preservacao}/>} label="Preservação" />
                                <FormControlLabel control={<Checkbox name={"urbano"} onChange={handleChangeTipos} checked={urbano}/>} label="Urbano" />
                            </div>
                        </div>
                        
                        <Divider />
                    
                        <div className="botoes">
                            <Button onClick={limpaMudancasFiltro} variant="text" size="large" sx={{ textTransform: 'none', color: 'black', '&:hover': { textDecoration: 'underline' } }}>Limpar</Button>
                            <Button onClick={enviaMudancasFiltro} variant="contained" size="large" sx={{ textTransform: 'none', background: 'black', '&:hover': { background: 'grey' } }}>Salvar</Button>
                        </div>

                    </FormGroup>
                
                </Menu>

            </div>
        
        </div>
    );
}

export default Filtro;