import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import LeiaMais from '../LeiaMaisBox';
import CardInfoParques from '../CardInfoParques';
import ListaFotosParque from '../ListaFotosParque/ListaFotosParque';
import { Divider } from '@mui/material';
import Fade from '@mui/material/Fade';
import Skeleton from '@mui/material/Skeleton';
import MensagemErro from '../MensagemErro';
import { Link } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { BrowserRouter as Router, useParams } from "react-router-dom";
import './estilo.css';

const DetalhesParque = ({setParqueSelecionado}) => {
    const { ulrDoParque } = useParams();
    const [parque, setParque] = useState([]);
    const [erroServidor, setErroServidor] = useState(false);
    const [estaCarregado, setEstaCarregado] = useState(false);
    
    if (localStorage.getItem('avisoCovid') === null) {
        localStorage.setItem('avisoCovid', true);
    }
    
    useEffect(() => {
        window.scroll(0, 0);
        setParqueSelecionado(true)
        const opcoes = {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'GET'
        }

        fetch(`${process.env.REACT_APP_API_URL}/parque/${ulrDoParque}`, opcoes)
            .then(resposta => resposta.json())
            .then(dados => {
                setParque(dados.data);
                setEstaCarregado(true);
                setErroServidor(false);
            })
            .catch(erro => {
                console.log(`Erro --> ${erro}`);
                setEstaCarregado(true);
                setErroServidor(true);
            });
    }, [ulrDoParque, setEstaCarregado, setParqueSelecionado]);

    const Conteudo = () => {
        if (estaCarregado && !erroServidor) {
            if(parque) {
                return(
                    <Fade in={estaCarregado} timeout={750} >
                        <div>
                            <div className="info-parques">
                                <div className="container-web">
                                    <div>
                                        <Link to="/">
                                            <ArrowBackIosIcon color="action" sx={{ fontSize: 30, width: "45px", marginBottom: "-4px", '&:hover': { fontSize: 32 } }}/>
                                        </Link>
                                    </div>
                                    <div>
                                        <Typography gutterBottom variant="h5" component="div">
                                            {parque.titulo}
                                        </Typography>
                                        <a href={parque.mapa} className="link-endereco" target="_blank" rel="noopener noreferrer">{parque.endereco}</a>
                                    </div>
                                </div>
                                <div className="info-parques-container">
                                    <ListaFotosParque parque={parque}/>
                                    <div className="container-mobile">
                                        <div style={{marginBottom: "20px", marginTop: "17px"}}>
                                            <Typography gutterBottom variant="h5" component="div">
                                                {parque.titulo}
                                            </Typography>
                                            <a href={parque.mapa} className="link-endereco" target="_blank" rel="noopener noreferrer">{parque.endereco}</a>
                                        </div>
                                        <Divider className="divider" style={{marginBottom: "-18px !important"}}/>
                                    </div>
                                    <div className="flexColumn">
                                        <div className="corpoInfo">
                                            <div>
                                                <CardInfoParques parque={parque}/>
                                                <Divider className="divider container-mobile"/>
                                            </div>
                                            <div style={{width: "100%"}}>
                                                {parque.particularidades &&
                                                    <section className="partInfra particularidades" style={{marginBottom: "20px"}}>
                                                        <Typography gutterBottom variant="h6" component="div">
                                                            Particularidades
                                                        </Typography>
                                                        <LeiaMais texto={parque.particularidades} linhas={4}/>
                                                    </section>
                                                }
                                                <Divider className="divider" style={{maxWidth: "800px"}}/>
                                                {parque.infraestrutura &&
                                                    <section className="partInfra" style={{marginBottom: "20px"}}>
                                                        <Typography gutterBottom variant="h6" component="div">
                                                            Infraestrutura
                                                        </Typography>
                                                        <LeiaMais texto={parque.infraestrutura} linhas={1}/>
                                                        <Divider className="divider container-mobile"/>
                                                    </section>
                                                }
                                            </div>
                                        </div>
                                        <section>
                                            <Typography gutterBottom variant="h6" component="div">
                                                Localização
                                            </Typography>
                                            <div className="loadingGif">
                                                <iframe src={parque.iframe_src} className="mapaIframe" title="Mapa com a localização do parque"></iframe>
                                            </div>
                                        </section>                         
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>
                )
            }else {
                return (
                    <MensagemErro tipo="resultado" />
                )
            }
        } else if (erroServidor){
            return (
                <MensagemErro tipo="servidor" />
            );
        } else {
            var skeletons = [];
            for (var i = 0; i < 1; i++) {
                skeletons.push(
                    <div key={i}>
                        <Skeleton animation="wave" variant="rectangular" width={'100%'} height={200} sx={{ borderRadius: '4px' }} />
                        <Skeleton animation="wave" variant="rectangular" width={'60%'} height={'1.75rem'} sx={{ margin: '1.5rem 0 0.8rem 1rem', borderRadius: '4px' }} />
                        <Skeleton animation="wave" variant="rectangular" width={'40%'} height={'1rem'} sx={{ margin: '0.5rem 0 0 1rem', borderRadius: '4px' }} />
                        <Skeleton animation="wave" variant="rectangular" width={'75%'} height={'1rem'} sx={{ margin: '0.5rem 0 0 1rem', borderRadius: '4px' }} />
                        <Skeleton animation="wave" variant="rectangular" width={'60%'} height={'1rem'} sx={{ margin: '0.5rem 0 0 1rem', borderRadius: '4px' }} />
                    </div>
                );
            }
            return (
                <div className="skeleton">
                    {skeletons}
                </div>
            );
        } 
    }

    return (
        <Conteudo />
    );
    
}
export default DetalhesParque;
