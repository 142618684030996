import React from 'react';
import './estilo.css';
import LeiaMais from '../LeiaMaisBox';

const CardInfoParques = (props) => {
  const dicionarioParques = {
    "CO": "Centro-Oeste",
    "L": "Leste",
    "N": "Norte",
    "S": "Sul"
  }

    return(
        <div className="card-info_container">
            <div className="card-info">
              <div>
                <h3 style={{margin: "0", fontWeight: "500", display: "inline"}}>Região: </h3>
                <h4 style={{marginTop: "7px", marginLeft: "5px", fontWeight: "400", display: "inline"}}>{dicionarioParques[props.parque.regiao]}</h4>
              </div>
              <div style={{marginTop: "15px"}}>
                <h3 style={{margin: "0", fontWeight: "500", display: "inline"}}>Subprefeitura: </h3>
                <h4 style={{marginTop: "7px", marginLeft: "5px", fontWeight: "400", display: "inline"}}>{props.parque.subprefeitura}</h4>
              </div>
              <div style={{marginTop: "15px"}}>
                <h3 style={{margin: "0", fontWeight: "500"}}>Horário de funcionamento: </h3>
                <h4 style={{marginTop: "7px", fontWeight: "400", marginBottom: "0"}}>{((props.parque.horario_funcionamento || "").charAt(0) || "").toUpperCase()}{(props.parque.horario_funcionamento || "").slice(1)}</h4>
              </div>
              {/* <div style={{marginTop: "15px"}}>
                <h3 style={{margin: "0", fontWeight: "500", display: "inline"}}>Capacidade máxima: </h3>
                <h4 style={{marginTop: "7px", marginLeft: "5px", fontWeight: "400", display: "inline"}}>
                  {
                    props.parque.limite === 0?
                      "Não aplica"
                    :props.parque.limite + " pessoas"
                  }
                </h4>
              </div> */}
              {
                props.parque.agendamento &&
                  <div style={{ marginTop: "15px" }}>
                    <h3 style={{ margin: "0", fontWeight: "500"}}>Agendamento: </h3>
                    <h4 style={{ marginTop: "7px", marginLeft: "12px", fontWeight: "400" }}>{props.parque.agendamento}</h4>
                  </div>
              }
              { props.parque.fone &&
                <div style={{marginTop: "15px"}}>
                  <h3 style={{margin: "0", fontWeight: "500", marginBottom: "7px", display: "inline"}}>Contato </h3>
                  <h4 style={{marginTop: "0", fontWeight: "400", display: "inline", marginLeft: "5px"}}>(11) {props.parque.fone}</h4>
                </div>
              }
              { props.parque.como_chegar &&
                <div style={{marginTop: "15px"}}>
                  <h3 style={{margin: "0", marginBottom: "3px", fontWeight: "500"}}>Como chegar</h3>
                  <LeiaMais texto={props.parque.como_chegar} linhas={3}/>
                </div>
              }
          </div>
        </div>
    ); 
}

export default CardInfoParques;