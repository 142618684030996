import React, { useState } from 'react';
import LogoWebparques from './img/HorizontalOriginalReduzido.png';
import LogoWebparquesMobile from './img/logo-webparquesReduzido.png';
import LogoSP from './img/logosp2Reduzido.png';
import LogoSVMA from './img/logosvmapretoReduzido.png';
import LogoSPMobile from './img/logosp-mobileReduzido.png';
import './estilo.css';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link, useNavigate } from 'react-router-dom';

const Header = ({ pesquisa, setPesquisa, setEstaCarregado, setPagina, pagina, parqueSelecionado }) => {
    const [pesquisaDigitada, setPesquisaDigitada] = useState("")
    const navigate = useNavigate();

    const enviaPesquisa = (evento) => {
        if (evento.key === 'Enter' && pesquisaDigitada !== pesquisa) {
            navigate("/");
            setPesquisa(pesquisaDigitada);
            setPagina(1);
            setEstaCarregado(false);
        }
    }

    const clickEnviaPesquisa = () => {
        if (pesquisaDigitada !== pesquisa) {
            navigate("/");
            setPesquisa(pesquisaDigitada);
            setPagina(1);
            setEstaCarregado(false);
        } 
    }

    const limpaPesquisa = (evento) => {
        if (evento.key === 'Enter') {
            setPesquisaDigitada("");
            setPesquisa("");
            setPagina(1);
            setEstaCarregado(false);
            if (window.innerWidth < 768){
                document.getElementById("caixaPesquisa").setAttribute('style', 'width: 60%; transition: width 0.3s ease;')
                setTimeout(function () {
                    document.getElementById("headerContainer").setAttribute('style', 'justify-content: space-between;')
                    document.getElementById("logoWebparques").setAttribute('style', 'display: inline-block;')
                    document.getElementById("logoPrefeitura").setAttribute('style', 'display: inline-block;')
                }, 200);
            }
        }
    }

    const clickLimpaPesquisa = () => {
        setPesquisaDigitada("");
        setPesquisa("");
        setPagina(1);
        setEstaCarregado(false);
        if (window.innerWidth < 768){
            document.getElementById("caixaPesquisa").setAttribute('style', 'width: 60%; transition: width 0.3s ease;')
            setTimeout(function () {
                document.getElementById("headerContainer").setAttribute('style', 'justify-content: space-between;')
                document.getElementById("logoWebparques").setAttribute('style', 'display: inline-block;')
                document.getElementById("logoPrefeitura").setAttribute('style', 'display: inline-block;')
            }, 200);
        }
    }

    const clickLimpaPesquisaParque = () => {
        setPesquisaDigitada("");
        setPesquisa("");
        setPagina(pagina);
        setEstaCarregado(false);
        if (window.innerWidth < 768){
            document.getElementById("caixaPesquisa").setAttribute('style', 'width: 60%; transition: width 0.3s ease;')
            setTimeout(function () {
                document.getElementById("headerContainer").setAttribute('style', 'justify-content: space-between;')
                document.getElementById("logoWebparques").setAttribute('style', 'display: inline-block;')
                document.getElementById("logoPrefeitura").setAttribute('style', 'display: inline-block;')
            }, 200);
        }
    }

    const registraPesquisa = (evento) => {
        setPesquisaDigitada(evento.target.value);
    }

    const IconePesquisa = () => {
        if (pesquisa === "" || pesquisaDigitada !== pesquisa) {
            return (
                <IconButton 
                    onClick={clickEnviaPesquisa} 
                    aria-label="Enviar pesquisa"
                >
                    <SearchIcon />
                </IconButton>
            );
        } else {
            return (
                <IconButton
                    onKeyDown={limpaPesquisa}
                    onClick={clickLimpaPesquisa}
                    aria-label="Limpar pesquisa"
                >
                    <CloseIcon />
                </IconButton>
            )
        }
    }
    const selecionada = () => {
        if (window.innerWidth < 768){
            document.getElementById("logoWebparques").setAttribute('style', 'display: none !important;')
            document.getElementById("logoPrefeitura").setAttribute('style', 'display: none !important;')
            document.getElementById("headerContainer").setAttribute('style', 'justify-content: center;')
            document.getElementById("caixaPesquisa").setAttribute('style', 'width: 100%; transition: width 0.3s ease;')
        }
    }
    const naoSelecionada = () => {
        if (window.innerWidth < 768){
            if(pesquisa === "" && pesquisaDigitada === pesquisa){
                document.getElementById("caixaPesquisa").setAttribute('style', 'width: 60%; transition: width 0.3s ease;')
                setTimeout(function () {
                    document.getElementById("headerContainer").setAttribute('style', 'justify-content: space-between;')
                    document.getElementById("logoWebparques").setAttribute('style', 'display: inline-block;')
                    document.getElementById("logoPrefeitura").setAttribute('style', 'display: inline-block;')
                  }, 200);
            }
        }
    }

    const BtnMobile = () => {
        if (parqueSelecionado){
            return(
                <Link to="/" onClick={clickLimpaPesquisaParque}>
                    <ArrowBackIosIcon color="action" sx={{ fontSize: 30, width: "45px", marginBottom: "-4px" }}/>
                </Link>
            )
        }else{
            if (pagina > 1){
                return(
                    <Link to="/" onClick={clickLimpaPesquisa}>
                        <img src={LogoWebparquesMobile} alt="Webparques" className="logo-webparques--mobile"/>
                    </Link>
                )
            }else{
                return(
                    <img src={LogoWebparquesMobile} alt="Webparques" className="logo-webparques--mobile"/>
                )
            }
        }
    }

    return(
        <>
        <header className="header">
            <div className="header__container" id="headerContainer">
                <div className="logo-webparques__container--mobile" id="logoWebparques">
                    <h1 className="invisivel">Webparques</h1> 
                    <BtnMobile />
                </div>

                <div className="logo-webparques__container">
                    <h1 className="invisivel">Webparques</h1>
                    { parqueSelecionado || pagina > 1 || pesquisa !== "" ?
                        <Link to="/" onClick={clickLimpaPesquisa} style={{height: "36.08px"}}>
                            <img src={LogoWebparques} alt="Webparques" className="logo-webparques"/>
                        </Link>
                      :
                        <img src={LogoWebparques} alt="Webparques" className="logo-webparques" style={{marginBottom: "0"}}/>
                    }
                </div>

                <div id="caixaPesquisa" className="pesquisa__container" tabIndex="-1">
                    <TextField 
                        fullWidth 
                        id="pesquisa" 
                        onKeyUp={enviaPesquisa}
                        onChange={registraPesquisa}
                        onFocus={selecionada}
                        onBlur={naoSelecionada}
                        sx={{ backgroundColor: 'white', borderRadius: 20, boxShadow: 3 }} 
                        label="Pesquisar"
                        className="pesquisa"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" >
                                    <IconePesquisa />
                                </InputAdornment>
                            )
                        }}
                        value={pesquisaDigitada}
                    />
                </div>

                <div className="logo-prefeitura__container">
                    <a href="https://www.prefeitura.sp.gov.br/cidade/secretarias/meio_ambiente/" className="svma__link"  target="_blank" rel="noopener noreferrer">
                        <img src={LogoSVMA} alt="Logo da Secretaria Municipal do Verde e do Meio Ambiente de São Paulo" className="svma__logo" />
                    </a>
                </div>

                <div className="logo-prefeitura__container--mobile" id="logoPrefeitura">
                    <a href="https://www.capital.sp.gov.br/" target="_blank" rel="noopener noreferrer">
                        <img src={LogoSPMobile} alt="Prefeitura de São Paulo" className="logo-prefeitura--mobile"/>
                    </a>
                </div>
            </div>
        </header>
        </>
    );
}

export default Header;