import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { Link } from 'react-router-dom';
import './estilo.css'

const CardParques = (props) => {
  return (
    <Link to={props.ulrDoParque} style={{textDecoration: "none"}}>
      <Card sx={{ maxHeight: 346, boxShadow: "rgb(0 0 0 / 22%) 0px 6px 16px", borderRadius: "12px", '&:hover': { filter: "brightness(97%)", transition: "all 0.6s ease" }}}>
        <CardActionArea sx={{ height: '100%' }}>
          <CardMedia
            component="img"
            height="200"
            image={props.imagemParque}
            alt={props.alt}
          />
          <CardContent sx={{ height: '100%' }}>
            <Typography gutterBottom variant="h5" component="div" sx={{maxHeight: "32.02px", overflow: "hidden"}}>
              {props.nomeDoParque}
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{maxHeight: "24px", overflow: "hidden"}}>
              Região: <span className="negrito">{props.regiao}</span>
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{maxHeight: "24px", overflow: "hidden"}}>
              {((props.horarioFuncionamento || "").charAt(0) || "").toUpperCase()}{(props.horarioFuncionamento || "").slice(1)}
            </Typography>
            {/* <Typography variant="body1" color="text.secondary" sx={{maxHeight: "24px", overflow: "hidden"}}>
              Capacidade máxima: <span className="negrito">{props.capacidade}</span>
            </Typography> */}
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
}

export default CardParques;