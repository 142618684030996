import React, { useEffect } from 'react';
import MensagemErro from '../MensagemErro';

const PaginaNaoEncontrada = ({setParqueSelecionado}) => {
    useEffect(() => {
        window.scroll(0, 0);
        setParqueSelecionado(true)
    }, [setParqueSelecionado]);
    return (
        <MensagemErro tipo="404" />
    );
}

export default PaginaNaoEncontrada;