import React from 'react';
import ReactDOM from 'react-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ptBR } from '@mui/material/locale';
import './index.css';
import 'normalize.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";

const theme = createTheme(
  {
    palette: {
      primary: { main: '#808080' },
    },
  },
  ptBR,
);

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById('root')
);