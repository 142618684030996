import * as React from 'react';
import ShowMoreText from "react-show-more-text";

import './estilo.css'

const LeiaMais = (props) => {
  return (
    <ShowMoreText
        lines={props.linhas}
        more="Ver mais"
        less="Ver menos"
        className="leiaMais"
        anchorClass="my-anchor-css-class"
        expanded={false}
        truncatedEndingComponent={"... "}
    >
      {/* <h4 style={{marginTop: "0", fontWeight: "400", textAlign: "left", marginBottom: "0"}} dangerouslySetInnerHTML={{__html: props.texto}}></h4> */}
      {/* {props.texto} */}
      <p style={{marginTop: "0", fontWeight: "400", textAlign: "left", marginBottom: "0"}} dangerouslySetInnerHTML={{__html: props.texto}}></p>

    </ShowMoreText>
);
}

export default LeiaMais;