import React from 'react';
import { Pagination } from '@mui/material';
import './estilo.css';

const Paginacao = ({ onChange, pagina, ultimaPagina }) => {
    return (
        <div className="paginacao__container">
            {
                window.innerWidth > 887 ?
                    <Pagination 
                        count={ultimaPagina}
                        onChange={onChange} 
                        page={pagina}
                        size="large"
                    />
                :
                    <Pagination 
                        count={ultimaPagina}
                        onChange={onChange} 
                        page={pagina}
                    />
            }
        </div>
    );
}

export default Paginacao;