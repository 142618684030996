import React from 'react';
import './estilo.css';
import LogoSP from './img/logosp2Reduzido.png';
import LogoSVMA from './img/logosvmapretoReduzido.png';
import IconeAcessoInformacao from './img/icone_acesso_a_informacao_preto.png';
import IconeFacebook from './img/icone_Facebook_preto.png';
import IconeInstagram from './img/icone_Instagram_preto.png';
import IconeTwitter from './img/icone_Twitter_preto.png';
import IconeNDTIC from './img/logoNDTICpreto.png';
import { Link } from 'react-router-dom';

const Footer = () => {
    return(
        <footer className="footer">
            <div className="footer__container">
                <div className="svma__container">
                    <a href="https://www.capital.sp.gov.br/" target="_blank" rel="noopener noreferrer">
                        <img src={LogoSP} alt="Prefeitura de São Paulo" className="logo-prefeitura"/>
                    </a>
                </div>

                <div className="links">
                    <ul className="links__lista">
                        <li className="links__item">
                            <Link to="/quem-somos" className="links__link">Quem somos nós</Link>
                        </li>
                        <li className="links__item">
                            <a href="http://transparencia.prefeitura.sp.gov.br/sistema-eletronico-de-informacao-ao-cidadao-e-sic/" className="links__link" target="_blank" rel="noreferrer">Acesso à informação <img className="icone-acinf" src={IconeAcessoInformacao} alt="" /></a>
                        </li>
                        <li className="links__item">
                            <Link to="/faq" className="links__link">Perguntas frequentes</Link>
                        </li>
                        <li className="links__item">
                            <a href="http://transparencia.prefeitura.sp.gov.br/" className="links__link" target="_blank" rel="noreferrer">Portal da transparência</a>
                        </li>
                    </ul>
                </div>

                <div className="divisoria" id="divisoria1"/>

                <div className="social">
                    <ul className="social__lista">
                        <li className="social__item">
                            <a href="https://www.facebook.com/svmasp/" className="social__link" target="_blank" rel="noreferrer">
                                <img src={IconeFacebook} alt="Facebook" className="social__icone" />
                            </a>
                        </li>
                        <li className="social__item">
                            <a href="https://www.instagram.com/svmasp/" className="social__link" target="_blank" rel="noreferrer">
                                <img src={IconeInstagram} alt="Instagram" width="24px" style={{marginTop: "2px", marginRight: "3px"}}/>
                            </a>
                        </li>
                        <li className="social__item" style={{paddingRight: "0"}}>
                            <a href="https://twitter.com/svmasp/" className="social__link" target="_blank" rel="noreferrer">
                                <img src={IconeTwitter} alt="Twitter" className="social__icone"/>
                            </a>
                        </li>
                    </ul>
                </div>
                
                <div className="divisoria" id="divisoria2"/>

                <div className="ndtic">
                    <div className="ndtic__link">
                        <img src={IconeNDTIC} alt="Logo da NDTIC" className="ndtic__logo" />
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;