import React, { useState, useEffect } from 'react';
import CardParques from '../CardParques';
import Filtro from '../Filtro';
import MensagemErro from '../MensagemErro';
import Paginacao from '../Paginacao';
import Fade from '@mui/material/Fade';
import Skeleton from '@mui/material/Skeleton';
import './estilo.css';

const ListaParques = ({ pesquisa, estaCarregado, setEstaCarregado, pagina, setPagina, setParqueSelecionado }) => {
    const [parques, setParques] = useState([]);
    const [regiao, setRegiao] = useState([]);
    const [tipo, setTipo] = useState([]);
    const [ultimaPagina, setUltimaPagina] = useState();
    const [erroServidor, setErroServidor] = useState(false);
    
    const dicionarioParques = {
        "CO": "Centro-Oeste",
        "L": "Leste",
        "N": "Norte",
        "S": "Sul"
    }

    const mudancaRegiao = (array) => {
        setPagina(1);
        setRegiao(array);
        setEstaCarregado(false);
    }

    const mudancaTipo = (array) => {
        setPagina(1);
        setTipo(array);
        setEstaCarregado(false);
    }

    const mudancaPagina = (event, value) => {
        if (value !== pagina) {
            if (window.screen.width <= 887) {
                window.scrollTo(0, 0);
            }
            setPagina(value);
            setEstaCarregado(false);
            document.querySelector(".grid-parques button").focus();
        }
    }

    useEffect(() => {
        setEstaCarregado(false)
        setParqueSelecionado(false)
        const opcoes = {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                'regiao': regiao,
                'tipo': tipo,
                'page': pagina,
                'titulo': pesquisa
            })
        }

        fetch(`${process.env.REACT_APP_API_URL}/parques`, opcoes)
            .then(resposta => resposta.json())
            .then(dados => {
                setUltimaPagina(dados.meta.last_page);
                setParques(dados.data);
                setEstaCarregado(true);
                setErroServidor(false);
            })
            .catch(erro => {
                console.log(`Erro --> ${erro}`);
                setEstaCarregado(true);
                setErroServidor(true);
            });
    }, [pagina, regiao, tipo, pesquisa, setEstaCarregado, setParqueSelecionado]);

    const Conteudo = () => {
        if (estaCarregado && !erroServidor) {
            if(parques.length > 0) {
                return (
                    <Fade in={estaCarregado} timeout={750} >
                        <div className="grid-parques__container">
                            <div className="grid-parques">
                                {parques.map((parque) => {
                                    return (
                                        <CardParques
                                            key={parque.id}
                                            imagemParque={parque.foto}
                                            alt={parque.titulo}
                                            nomeDoParque={parque.titulo}
                                            ulrDoParque={"/" + parque.id}
                                            regiao={dicionarioParques[parque.regiao]}
                                            horarioFuncionamento={parque.horario_funcionamento}
                                            capacidade={parque.limite === 0 ? "Não se aplica" : parque.limite + " pessoas"}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </Fade>
                ); 
            } else {
                return (
                    <MensagemErro tipo="resultado" />
                )
            }
        } else if (erroServidor){
            return (
                <MensagemErro tipo="servidor" />
            );
        } else {
            var skeletons = [];
            for (var i = 0; i < 6; i++) {
                skeletons.push(
                    <div key={i}>
                        <Skeleton animation="wave" variant="rectangular" width={'100%'} height={200} sx={{ borderRadius: '12px 12px 0 0' }} />
                        <Skeleton animation="wave" variant="rectangular" width={'60%'} height={'1.75rem'} sx={{ margin: '1.5rem 0 0.8rem 1rem', borderRadius: '4px' }} />
                        <Skeleton animation="wave" variant="rectangular" width={'40%'} height={'1rem'} sx={{ margin: '0.5rem 0 0 1rem', borderRadius: '4px' }} />
                        <Skeleton animation="wave" variant="rectangular" width={'75%'} height={'1rem'} sx={{ margin: '0.5rem 0 0 1rem', borderRadius: '4px' }} />
                        <Skeleton animation="wave" variant="rectangular" width={'60%'} height={'1rem'} sx={{ margin: '0.5rem 0 0 1rem', borderRadius: '4px' }} />
                    </div>
                );
            }
            return (
                <div className="grid-parques__container">
                    <div className="grid-parques">
                        {skeletons}
                    </div>
                </div>
            );
        } 
    }
    
    return (
        <section className="lista-parques">

            <div className="selecao__container">
                <section className="selecao">
                    <Filtro 
                        mudancaRegiao = {mudancaRegiao}
                        mudancaTipo = {mudancaTipo}
                    />
                </section>
            </div>
        
            <Conteudo />

            <Paginacao onChange={mudancaPagina} pagina={pagina} ultimaPagina={ultimaPagina} />

        </section>
    );
}

export default ListaParques;